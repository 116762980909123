import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import jwtDecode from 'jwt-decode';

if (environment.production) {
  enableProdMode();
}

// export function getCookie(name) {
//   const cookies = document.cookie.split(';').map(cookie => cookie.trim());
//   const cookie = cookies.find(cookie => cookie.startsWith(`${name}=`));
//   if (!cookie) return null;
//   return cookie.split('=')[1];
// }

export function getCookie(name: string): string[] {
  const cookies = document.cookie.split(';');
  const access_token_cookies = cookies
    .filter((cookie) => cookie.trim().startsWith(name))
    .map((cookie) => cookie.split('=')[1]);
  return access_token_cookies;
}

export function getValidAccessToken(): string | null {
  const accessTokenCookies = getCookie('access_token');

  for (let token of accessTokenCookies) {
    try {
      const decodedToken = jwtDecode<any>(token);
      if (decodedToken.aud === 'https://certificatverd.salut.gencat.cat') {
        return token;
      }
    } catch (e) {
      console.error('Error al decodificar el token:', e);
    }
  }

  return null;
}

export function deleteCookie(name: string) {
  const cookie = getValidAccessToken();
  if (cookie) {
    if (!environment.deploy) {
      document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    } else {
      let domain = window.location.host;
      while (domain.indexOf('.') != domain.lastIndexOf('.')) {
        domain = domain.substring(domain.indexOf('.') + 1);
      }
      document.cookie = `${name}=; Path=/; Domain=${domain}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    }
  }
}

//console.log('>>>>>>>>> Bootstrapping application...');

//const cip = getCookie('pacient_cip');
//console.log('......... Get-Cookie(pacient_cip): ' + cip);
//if (cip) {
//  sessionStorage.setItem('cip', cip);
//  deleteCookie('pacient_cip');
//}
// if (!localStorage.getItem('cip') && cip) localStorage.setItem('cip', cip);

//const accessTokenHC=''

const accessToken = getValidAccessToken();
//console.log('......... Get-Cookie(access_token): ' + accessToken);
if (accessToken) {
  sessionStorage.setItem('accessToken', accessToken);
  deleteCookie('access_token');
}
// } else {
//   //console.log('No hay ninguna cookie seteada')
//   //console.log('Se usara una hardcodeada')
//   sessionStorage.setItem('accessToken', accessTokenHC);
//   deleteCookie('access_token');
// }
// if (!localStorage.getItem('accessToken') && accessToken) localStorage.setItem('accessToken', accessToken);

// const refreshToken= ''
//const refreshToken = getCookie('refresh_token');
//console.log('......... Get-Cookie(refreshToken): ' + refreshToken);
// if (refreshToken) {
//   sessionStorage.setItem('refreshToken', refreshToken);
//   deleteCookie('refresh_token');
// }
// if (!localStorage.getItem('refreshToken') && refreshToken) localStorage.setItem('refreshToken', refreshToken);

platformBrowserDynamic().bootstrapModule(AppModule);
